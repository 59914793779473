<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import useNetw from "@useNetw";
import Swal from "sweetalert2";
import btnComponent from "@/components/btnComponent";
import Multiselect from "vue-multiselect";
// import branchComponent from "@/components/branchComponent.vue";
// import { tableData } from "./dataAdvancedtable";

/**
 * Advanced-table component
 */
export default {
  page: {
    title: "ข้อมูลรถ",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: { Layout, PageHeader, btnComponent, Multiselect },
  data() {
    return {
      // tableData: tableData,

      title: "ข้อมูลรถ",
      items: [
        {
          text: "ลูกค้าสัมพันธ์",
          active: true,
        },
        {
          text: "ข้อมูลรถ",
          active: false,
        },
      ],
      rowsSelect: [],
      chk: "",
      selected: [],
      rowsTotalpages: "",
      rowsperPage: "",
      rowsPage: "",
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      arrTest: [],
      totalRecords: 0,
      pageOptions: [10, 25, 50, 100],
      optionBranch: [],
      filter: {
        vin: "",
        licensePlate: "",
        userBranchId: "",
      },
      userBranch: "",
      filterOn: [],
      rowsTest: [],
      sortBy: "age",
      sortDesc: false,
      fields: [
        {
          key: "index",
          label: "ลำดับ",
          sortable: false,
        },
        {
          key: "vin",
          sortable: true,
          label: "เลขตัวถัง",
        },
        {
          key: "licensePlate",
          sortable: true,
          label: "ป้ายทะเบียน",
        },
        {
          key: "vehicleBrandEn",
          sortable: true,
          label: "ยี่ห้อ",
        },
        {
          key: "vehicleModelEn",
          sortable: true,
          label: "รุ่น",
        },
        {
          key: "branchName",
          sortable: true,
          label: "สาขา",
        },
        {
          key: "action",
          label: appConfig.action,
          sortable: false,
        },
      ],
      overlayFlag: false,
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.rowsTest.length;
    },
  },
  mounted() {
    const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
    const arrayBranch = [];
    const arrayBranchAll = [];
    user.userAccessBranch.forEach((item) => {
      arrayBranch.push(item.branchId);
      arrayBranchAll.push(item);
    });
    this.userBranch = arrayBranch;
    this.optionBranch = arrayBranchAll;
    this.totalRows = this.rowsTest.total;
  },
  created() {
    this.getData();
    // this.select()
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      // this.currentPage = 1;
    },
    getData: function() {
      this.overlayFlag = true;
      useNetw
        .get("api/vehicle", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            vin: this.filter.vin,
            licensePlate: this.filter.licensePlate,
            "branchId[]": this.filter.userBranchId.branchId,
          },
        })
        .then((response) => {
          this.rowsTest = response.data.data;
          this.rowsTest.to = response.data.to;
          this.rowsTest.from = response.data.from;
          this.rowsTest.total = response.data.total;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            // this.$router.push("/login");
          }
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    handleChangePage(page) {
      this.currentPage = page;
      this.getData();
    },
    handlePageChange(active) {
      this.perPage = active;
      this.currentPage = 1;
      this.getData();
    },
    onRowSelected(rowsTest) {
      this.selected = rowsTest;
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },
    deleteVehicle(rowsTest) {
      this.vehicleId = rowsTest;
      this.overlayFlag = true;
      useNetw
        .delete("api/vehicle/delete", {
          data: {
            vehicleId: this.vehicleId,
          },
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.deleterSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.getData();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    handleSearch() {
      this.getData();
    },
    alertDelete(vehicleId) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ms-2",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "ต้องการลบหรือไม่?",
          text: "ต้องการ Vehicle นี้หรือไม่ !!",
          icon: "warning",
          confirmButtonText: "OK",
          cancelButtonText: "Cancel!",
          showCancelButton: true,
        })
        .then((result) => {
          if (result.value) {
            this.deleteVehicle(vehicleId);
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
            swalWithBootstrapButtons.fire(
              "Cancelled",
              "ยกเลิกเรียบร้อยแล้ว",
              "error"
            );
          }
        });
    },
  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <b-overlay
          :show="overlayFlag"
          :spinner-variant="this.$overlayVariant"
          :spinner-type="this.$overlayType"
          :rounded="this.$overlayRounded"
        >
          <div class="card">
            <div class="card-body">
              <!-- <h4 class="card-title">Vehicle List</h4> -->
              <div class="row">
                <div class="col-sm-12 col-md-2"></div>
              </div>
              <!-- Search -->
              <div class="row justify-content-md-center">
                <div class="col-sm-12 col-md-12">
                  <div class="row">
                    <div class="col-12 col-sm-12 col-md-12">
                      <div class="row">
                        <div class="col-6 col-sm-6 col-md-4">
                          <div class="mb-3 position-relative">
                            สาขา:
                            <multiselect
                              v-model="filter.userBranchId"
                              label="nameTh"
                              type="search"
                              :options="optionBranch"
                              :show-labels="false"
                              open-direction="bottom"
                              placeholder=""
                            >
                              <span slot="noResult">ไม่พบข้อมูล</span>
                            </multiselect>
                          </div>
                        </div>
                        <div class="col-6 col-sm-6 col-md-4">
                          <div class="mb-3 position-relative">
                            เลขตัวถัง:
                            <b-form-input
                              v-model="filter.vin"
                              type="search"
                              class="form-control"
                              @keyup.enter="handleSearch"
                            ></b-form-input>
                          </div>
                        </div>
                        <div class="col-6 col-sm-6 col-md-4">
                          <div class="mb-3 position-relative">
                            ป้ายทะเบียน:
                            <b-form-input
                              v-model="filter.licensePlate"
                              type="search"
                              class="form-control"
                              @keyup.enter="handleSearch"
                            ></b-form-input>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12 col-sm-12 col-md-12 text-end">
                            &nbsp;
                            <btnComponent
                              @click="handleSearch"
                              changeStyle="search"
                            >
                            </btnComponent>
                            <b-button
                              class="btn m-2"
                              variant="primary"
                              type="submit"
                              :to="{ name: 'vehicleform' }"
                            >
                              <i class="uil-plus"></i>
                              ลงทะเบียนรถใหม่
                            </b-button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row align-items-center">
                    <div class="col-sm-12 col-md-3">
                      <div id="tickets-table_length" class="dataTables_length">
                        <label class="d-inline-flex align-items-center">
                          แสดงผล&nbsp;
                          <b-form-select
                            v-model="perPage"
                            size="sm"
                            :options="pageOptions"
                            @input="handlePageChange"
                          ></b-form-select
                          >&nbsp;รายการ
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- End search -->
              </div>
              <!-- Table -->
              <div class="table-responsive mb-0">
                <b-table
                  :items="rowsTest"
                  :fields="fields"
                  :current-page="1"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  @filtered="onFiltered"
                  responsive="sm"
                  ref="selectableTable"
                  selectable
                  @row-selected="onRowSelected"
                >
                  <template #cell(index)="rowsTest">
                    {{ rowsTest.index + 1 }}
                  </template>
                  <template v-slot:cell(action)="rowsTest">
                    <ul class="list-inline mb-0">
                      <li class="list-inline-item">
                        <router-link
                          href="javascript:void(0);"
                          class="px-2 text-primary"
                          v-b-tooltip.hover
                          :to="{
                            name: 'vehicleformdetail',
                            params: { vehicleId: rowsTest.item.vehicleId },
                          }"
                          title="Edit"
                        >
                          <i class="uil uil-pen font-size-18"></i>
                        </router-link>
                      </li>
                      <li class="list-inline-item">
                        <a
                          href="javascript:void(0);"
                          class="px-2 text-danger"
                          v-b-tooltip.hover
                          title="Delete"
                          @click="alertDelete(rowsTest.item.vehicleId)"
                        >
                          <i class="uil uil-trash-alt font-size-18"></i>
                        </a>
                      </li>
                    </ul>
                  </template>
                </b-table>
                <!-- <p class="float-end">
                  <b-button class="m-2" size="sm" @click="selectAllRows"
                    >Select all</b-button
                  >
                  <b-button class="m-2" size="sm" @click="clearSelected"
                    >Clear selected</b-button
                  >
                </p> -->
              </div>
              <div class="row">
                <div class="col">
                  <div class="align-items-center">
                    แสดงผล {{ perPage }} หน้า {{ currentPage }} จาก
                    {{ this.rowsTest.total }} รายการ
                  </div>
                  <div
                    class="dataTables_paginate paging_simple_numbers float-end"
                  >
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <b-pagination
                        v-model="currentPage"
                        :total-rows="rowsTest.total"
                        :per-page="perPage"
                        @change="handleChangePage"
                      ></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-overlay>
      </div>
    </div>
  </Layout>
</template>
